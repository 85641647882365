<template>
  <div class="account-management">
    <div class="account-management__header">
      <SearchAccount
        @searchAccount="searchAccount"
        @accountClearSearchField="accountClearSearchField"
      />
      <div class="account-management__header-buttons">
        <i v-b-popover.hover.top="'Filters'">
          <button class="button-container" @click="openFilters()">
            <el-badge :value="numberFilters">
              <img src="@/assets/scss/icons/filter.svg" alt="filter-icon" />
            </el-badge>
          </button>
        </i>
        <i v-b-popover.hover.top="'Columns'">
          <button class="button-container" @click="openColumns()">
            <el-badge :value="numberColumns">
              <img class="icons-tools" src="@/assets/scss/icons/girdAdd.svg" alt="" />
            </el-badge>
          </button>
        </i>
        <ExportTableButton
          v-b-popover.hover.top="'Download excel'"
          :filtersTable="filtersTable"
          :columnFields="columnFields"
          :loadingExport="loadingExport"
          @getAllAccountToDownloadExcel="getAllAccountToDownloadExcel"
          ref="export-table"
        />
        <button
          class="new-account-button"
          @click="changeView('CreateNewAccount')"
          v-b-popover.hover.top="'New Account'"
        >
          <span v-if="screenWidth > 800"> New Account </span>
          <span v-else>
            <i class="ion ion-add-outline"></i>
          </span>
        </button>
      </div>
      <div class="columns-float-container" v-if="showColumnsFilter">
        <AddColumnTable
          :savedFilters="savedFilters"
          @selectedColumns="selectedColumns"
          @resetColumns="resetColumns"
          @closeColumns="closeColumns"
        />
      </div>
      <div class="filters-float-container" v-if="showFilters">
        <FiltersAccountTable
          :savedFilters="savedFilters"
          :accountModesList="accountModesList"
          @getFiltersInformation="getFiltersInformation"
          @selectedFilters="selectedFilters"
          @resetFilters="resetFilters"
          @closeFilters="closeFilters"
        />
      </div>
    </div>
    <AccountTable
      :tableData="filteredAccounts"
      :loading="loading"
      :updating="updating"
      :allDataHasBeenLoaded="allDataHasBeenLoaded"
      :columnFields="columnFields"
      @loadMoreItems="loadMoreItems"
      :reRenderKey="key"
      ref="table"
    />
  </div>
</template>

<script>
import onReSize from "../../Extend/onResize";
import SearchAccount from "./components/SearchAccount.vue";
import ExportTableButton from "./components/ExportTableButton.vue";
import AccountTable from "./components/AccountTable.vue";
import FiltersAccountTable from "./components/FiltersAccountTable.vue";
import AddColumnTable from "./components/AddColumnTable.vue";

export default {
  name: "AccountManagement",
  mixins: [onReSize],
  components: {
    SearchAccount,
    ExportTableButton,
    AccountTable,
    FiltersAccountTable,
    AddColumnTable,
  },
  data() {
    return {
      originalColumns: [],
      columnFields: [
        {
          label: "SALESFORCE ACCOUNT",
          field: "account_name",
          field_to_excel: "account_name",
          minWidth: 250,
          fixed: "left",
          enableCopy: true,
        },
        {
          label: "ADDRESS",
          field: "address",
          field_to_excel: "company_address",
          minWidth: 220,
          fixed: false,
          enableCopy: true,
        },
        {
          label: "MODES",
          field: "modes",
          field_to_excel: "modes",
          minWidth: 250,
          fixed: false,
          enableCopy: true,
        },
        {
          label: "BRANDING",
          field: "branding",
          field_to_excel: "is_branded",
          minWidth: 100,
          fixed: false,
          enableCopy: true,
        },
        {
          label: "CHILDREN ACCOUNT",
          field: "children_account",
          field_to_excel: "children_accounts",
          minWidth: 200,
          fixed: false,
          enableCopy: true,
        },
        {
          label: "CREATED DATE",
          field: "date",
          field_to_excel: "created_at",
          minWidth: 150,
          fixed: false,
          enableCopy: true,
        },
        {
          label: "TOOLS",
          field: "tools",
          minWidth: 240,
          fixed: false,
          enableCopy: false,
        },
      ],
      skip: 0,
      limit: 25,
      showFilters: false,
      showColumnsFilter: false,
      filteredAccounts: [],
      loadingExport: false,
      filtersTable: {},
      savedFilters: null,
      columnsFilters: null,
      numberFilters: 0,
      numberColumns: 0,
      allAccountsAreLoaded: false,
      loading: true,
      updating: false,
      allDataHasBeenLoaded: false,
      key: 0,
      accountModesList: [],
      allowedIdModes: [
        "a0k1I0000005NnyQAE",
        "a0k1I0000005NnyQAE",
        "a0k1I0000005NnzQAE",
        "a0k1I000000WkMOQA0",
        "a0k1I000000WkMEQA0",
        "a0k1I00000B7RocQAF",
        "a0k3s00000d6xAlAAI",
      ],
    };
  },
  async created() {
    this.getFilterFromLocalStorage();
    if (this.savedFilters) {
      this.selectedColumns({ filters: this.savedFilters?.columns, executed: false });
      this.selectedFilters({ filters: this.savedFilters?.filters, executed: false });
      this.savedLocalSotrageFilter();
      this.badgeFiltersQuantityHandler();
    }
    this.accountModesList = await this.getModesList();
    this.accountModesList = this.accountModesList.filter((item) => this.allowedIdModes.includes(item.id));
    await this.getAccounts(this.filtersTable, false);
  },
  computed: {
    filters() {
      return {
        columns: this.columnsFilters,
        filters: this.filtersTable,
      };
    },
    accounts: {
      get() {
        return this.$store.getters["account/getAccounts"];
      },
    },
  },
  watch: {
    accounts: {
      immediate: true,
      deep: true,
      handler() {
        this.filteredAccounts = this.accounts.map((item) => ({
          ...item,
          // is_active: !item.is_active ? "Inactive" : "Active",
        }));
        this.key += 1;
      },
    },
  },
  methods: {
    async searchAccount(search) {
      this.loading = true;
      this.$store.commit("account/resetAccounts");
      this.skip = 0;
      this.filtersTable.search = search;
      await this.getAccounts(this.filtersTable, true);
      this.loading = false;
    },
    async accountClearSearchField() {
      this.loading = true;
      this.filtersTable.search = "";
      await this.getAccounts(this.filtersTable, true);
      this.savedLocalSotrageFilter();
      this.getFilterFromLocalStorage();
      this.loading = false;
    },
    async getAccounts(params, resetList) {
      this.loading = true;
      if (resetList) {
        this.$store.commit("account/resetAccounts");
        this.skip = 0;
        this.limit = 25;
        this.allDataHasBeenLoaded = false;
      }
      const filteredParams = this.filtersSchemaToSend(params);
      const response = await this.$store.dispatch("account/getAllAccounts", {
        skip: this.skip,
        limit: this.limit,
        ...filteredParams,
      });
      if (!response.length || response.length < this.limit) {
        this.allDataHasBeenLoaded = true;
      }
      this.loading = false;
      return response;
    },
    async getAllAccountToDownloadExcel(params) {
      this.loadingExport = true;
      const filteredParams = this.filtersSchemaToSend(params);
      const response = await this.$store.dispatch("account/getAllAccountToDownloadExcel", {
        skip: 0,
        limit: 200,
        ...filteredParams,
      });
      this.$refs["export-table"].exportExcel(response);
      this.loadingExport = false;
    },
    filtersSchemaToSend(params) {
      const filteredParams = Object.entries(params || {}).reduce((acc, [key, value]) => {
        if (value !== null && value !== "" && (!Array.isArray(value) || value.length > 0)) {
          acc[key] = value;
        }
        return acc;
      }, {});
      return filteredParams;
    },
    async loadMoreItems() {
      this.updating = true;
      this.$refs.table.disableScroll();
      this.skip += this.limit;
      await this.getAccounts(this.filtersTable, false);
      this.$refs.table.enableScroll();
      this.updating = false;
    },
    async selectedFilters({ filters, executed }) {
      this.filtersTable = filters;
      if (executed) {
        this.savedLocalSotrageFilter();
        this.badgeFiltersQuantityHandler();
        this.getFilterFromLocalStorage();
        await this.getAccounts(this.filtersTable, true);
      }
    },
    getFiltersInformation(filters) {
      this.filtersTable = filters;
      this.savedLocalSotrageFilter();
      this.badgeFiltersQuantityHandler();
      this.getFilterFromLocalStorage();
    },
    resetFilters() {
      this.numberFilters = 0;
      this.savedFilters = {};
      this.savedLocalSotrageFilter();
    },
    selectedColumns({ filters, executed }) {
      this.columnsFilters = filters;
      if (!this.originalColumns.length) {
        this.originalColumns = [...this.columnFields];
      }
      const columnsArray = this.validateColumnsSelected(filters);
      this.addColumns(columnsArray, executed);
      if (executed) {
        this.savedLocalSotrageFilter();
        this.badgeFiltersQuantityHandler();
        this.getFilterFromLocalStorage();
      }
    },
    addColumns(columns, executedRefFunction) {
      const fieldMapping = {
        "Company Name": "company_name",
        Magaya: "network_id",
      };
      const newColumns = [
        ...this.originalColumns.slice(0, 1),
        ...columns.map((columnName) => {
          const field = fieldMapping[columnName] || columnName.toLowerCase().replace(/\s+/g, "_");
          return {
            label: columnName.toUpperCase(),
            field,
            field_to_excel: field,
            minWidth: columnName === "Company Name" ? 200 : 100,
            fixed: false,
            enableCopy: false,
          };
        }),
        ...this.originalColumns.slice(1),
      ];
      this.columnFields = newColumns;
      if (executedRefFunction) {
        this.$refs.table.reRenderTable();
      }
    },
    resetColumns() {
      this.numberColumns = 0;
      this.columnsFilters = null;
      if (this.originalColumns.length) {
        this.columnFields = [...this.originalColumns];
        this.originalColumns = [];
      }
    },
    validateColumnsSelected(data) {
      if (!data || !data.length) {
        return [];
      }
      const checkedItems = data.filter((item) => item.value);
      const columnsArray = checkedItems.map((item) => item.text);
      return columnsArray;
    },
    openFilters() {
      this.showFilters = true;
    },
    closeFilters() {
      this.showFilters = false;
    },
    openColumns() {
      this.showColumnsFilter = true;
    },
    closeColumns() {
      this.showColumnsFilter = false;
    },
    savedLocalSotrageFilter() {
      localStorage.setItem("account-management-table", JSON.stringify(this.filters));
    },
    getFilterFromLocalStorage() {
      const infoLocalStorage = JSON.parse(localStorage.getItem("account-management-table"));
      if (infoLocalStorage) {
        // eslint-disable-next-line prefer-object-spread
        this.savedFilters = Object.assign({}, this.savedFilters, infoLocalStorage);
      }
      this.badgeFiltersQuantityHandler();
    },
    badgeFiltersQuantityHandler() {
      if (this.columnsFilters) {
        this.numberColumns = 0;
        this.numberColumns = this.validateColumnsSelected(this.columnsFilters).length;
      }
      if (this.filtersTable) {
        this.numberFilters = 0;
        if (this.filtersTable.account_name) {
          this.numberFilters += 1;
        }
        if (this.filtersTable.children_accounts || this.filtersTable.children_accounts === false) {
          this.numberFilters += 1;
        }
        if (this.filtersTable.company_name) {
          this.numberFilters += 1;
        }
        if (this.filtersTable.modes && this.filtersTable.modes.length > 0) {
          this.numberFilters += 1;
        }
        if (this.filtersTable.init_created_at || this.filtersTable.end_created_at) {
          this.numberFilters += 1;
        }
        if (this.filtersTable.is_active || this.filtersTable.is_active === false) {
          this.numberFilters += 1;
        }
        if (this.filtersTable.is_branded || this.filtersTable.is_branded === false) {
          this.numberFilters += 1;
        }
        if (this.filtersTable.network_id || this.filtersTable.network_id === false) {
          this.numberFilters += 1;
        }
      }
    },
    async getModesList() {
      const response = await this.$store.dispatch("account/getAccountModes");
      return response;
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-management {
  @include splitted-view-styles;
  margin: 0px;
  padding-top: 50px;
  width: 100%;
  &__header {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 10px;
    gap: 10px;
    position: relative;
    &-buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
.button-container {
  border-radius: 5px;
  border: 2px solid $color-border-container;
  color: $color-primary-company;
  background-color: #efefef;
  padding: 0.1rem 0.4rem;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    border-color: $color-border-container;
    background-color: rgba(0, 185, 204, 0.0588235294);
  }
}
.new-account-button {
  @include primary-button;
  height: auto;
  padding: 5.5px;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  @media (max-width: 800px) {
    width: 30px;
    border-radius: 50%;
  }
}
.button-container img {
  width: 18px;
}
.filters-float-container {
  position: absolute;
  top: 41px;
  right: 220px;
  z-index: 99;
  border: 2px solid $color-border-container;
  border-radius: 10px;
  padding: 5px;
  background-color: $color-white;
}
.columns-float-container {
  position: absolute;
  top: 41px;
  right: 177px;
  z-index: 99;
  border: 2px solid $color-border-container;
  border-radius: 10px;
  padding: 5px;
  background-color: $color-white;
}
::v-deep .el-badge__content,
.el-progress.is-exception .el-progress-bar__inner {
  background-color: $color-border-container;
}
::v-deep .el-badge__content.is-fixed {
  display: flex;
  align-items: center;
  z-index: 10;
  font-size: 11px;
}
</style>
