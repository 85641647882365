<template>
  <div class="account-tools-list" @click.stop>
    <div class="account-tools-list__item" v-b-popover.hover.top="'Update status'">
      <el-switch v-model="status" @change="updateAccountStatus()"></el-switch>
      <label> Status </label>
    </div>
    <div class="account-tools-list__item" v-b-popover.hover.top="'Edit'">
      <button class="button-container" @click.stop="openEditModal()">
        <img src="../../../assets/scss/icons/pen-to-square-regular.svg" alt="" />
      </button>
    </div>
    <div
      class="account-tools-list__item"
      @click.stop="openStatementModal()"
      v-b-popover.hover.top="'Upload Statement'"
    >
      <button class="button-container">
        <i class="ion ion-document-attach-outline"></i>
      </button>
    </div>
    <div
      class="account-tools-list__item"
      @click.stop="changeView()"
      v-b-popover.hover.top="'Users'"
    >
      <button class="button-container">
        <img src="../../../assets/scss/icons/users-solid.svg" alt="" />
      </button>
    </div>
    <div
      class="account-tools-list__item"
      @click.stop="loginWithAnotherAccount()"
      v-b-popover.hover.top="'Login with'"
    >
      <button class="button-container">
        <img src="../../../assets/scss/icons/right-from-bracket-solid.svg" alt="" />
      </button>
    </div>
    <div
      class="account-tools-list__item"
      @click.stop="deleteAccount()"
      v-b-popover.hover.top="'Delete'"
    >
      <button class="button-container">
        <img src="../../../assets/scss/icons/trash-can-solid.svg" alt="" />
      </button>
    </div>
    <UpdateStatementsModal
      :showStatementModal="showStatementModal"
      :id="account.id"
      @openStatementModal="openStatementModal"
      @closeStatementModal="closeStatementModal"
    />
    <CustomModal
      v-model="showEditModal"
      modalTitle="Edit Account"
      size="lg"
      :centered="true"
      :no-close-on-backdrop="true"
    >
      <template #modal-content>
        <AccountEditModal :account="account" @closeModal="closeModal" />
      </template>
    </CustomModal>
  </div>
</template>

<script>
import clearLocalStorage from "@/utils/ClearLocalStorage";
import DecodeToken from "@/utils/DecodeToken";
import CustomModal from "../../../components/CustomModal.vue";
import AccountEditModal from "./AccountEditModal.vue";
import UpdateStatementsModal from "../children/Accounts/components/UpdateStatementsModal.vue";

export default {
  name: "AccountTools",
  props: {
    account: Object,
  },
  components: {
    CustomModal,
    AccountEditModal,
    UpdateStatementsModal,
  },
  data() {
    return {
      status: true,
      showEditModal: false,
      showStatementModal: false,
    };
  },
  created() {
    this.status = this.account.is_active;
    // this.status = accountStatus === "Active";
  },
  methods: {
    // Status
    async updateAccountStatus() {
      const updateAccount = await this.swal({
        title: "Are you sure?",
        text: "This account will be updated",
        icon: "warning",
        buttons: ["Cancel", "Update"],
        dangerMode: true,
      });
      if (updateAccount) {
        const payload = {
          id: this.account.id,
          body: {
            ...this.account,
            is_active: this.status,
          },
        };
        this.updateAccountInformation(payload);
      } else {
        this.status = this.account.is_active;
      }
    },
    async updateAccountInformation(payload) {
      await this.$store.dispatch("account/updateAccount", payload);
      this.$store.commit("account/updateAccount", payload);
      this.swal({
        title: "Success!",
        text: "Account updated successfully",
        icon: "success",
      });
    },
    changeView() {
      this.$router.push({ name: "UserManagement", params: { accountId: this.account.id } });
    },
    openEditModal() {
      this.showEditModal = true;
    },
    // Login
    async loginWithAnotherAccount() {
      const loginWithAnotherAccount = await this.swal({
        title: "Log in with this account?",
        text: "The current account will be logged out.",
        icon: "warning",
        buttons: ["Cancel", "Login"],
      });
      if (loginWithAnotherAccount) {
        this.loginWithAccountId(this.account.id);
      }
    },
    async loginWithAccountId(accountId) {
      this.$emit("loadingLoginAsAUser", true);
      const tokenAccount = await this.$store.dispatch("login/loginWithOtherAccount", accountId);
      if (tokenAccount.access_token) {
        clearLocalStorage();
        const decodedToken = DecodeToken(tokenAccount.access_token);
        this.$ls.set("portal-front-token", tokenAccount.access_token);
        await this.$store.dispatch("login/getAccountSettings", {
          access_token: tokenAccount.access_token,
          decodedToken,
        });
        const baseUrl = window.location.origin;
        const routeName = "my-loads";
        window.location.href = `${baseUrl}/${routeName}`;
      }
      this.$emit("loadingLoginAsAUser", false);
    },
    // Delete
    async deleteAccount() {
      if (!this.$options.filters.checkPermission("delete:account")) {
        return;
      }
      const deleteAccount = await this.swal({
        title: "Are you sure?",
        text: "This account will be permanently deleted. This action is irreversible.",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
      if (deleteAccount) {
        await this.$store.dispatch("account/deleteAccount", {
          id: this.account.id,
        });
        this.$store.commit("account/deleteAccount", { id: this.account.id });
      }
    },
    closeModal() {
      this.showEditModal = false;
    },
    closeStatementModal() {
      this.showStatementModal = false;
    },
    openStatementModal() {
      this.showStatementModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-tools-list {
  display: flex;
  justify-content: center;
  gap: 10px;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 20px;
    color: $color-primary-company;
    label {
      font-size: 11px;
      margin: 0px;
      font-weight: 600;
    }
  }
}
.button-container {
  background: none;
  border: none;
  outline: none;
  padding: none;
  margin: none;
  margin: 0px;
  padding: 0px;
  color: $color-primary-company;
}
.not-allowed-cursor ::v-deep .el-switch.is-checked .el-switch__core {
  cursor: not-allowed;
}

::v-deep {
  .active {
    span {
      border-color: $color-border-container !important;
      background-color: $color-border-container !important;
    }
  }
  .el-switch.is-checked .el-switch__core {
    border-color: $color-border-container !important;
    background-color: $color-border-container !important;
  }
}
</style>
