<template>
  <div class="export-table-button">
    <button class="button-container" @click="exportData()" title="Download excel">
      <i v-if="!loadingExport" class="el-icon-download"></i>
      <b-spinner class="spinner" v-if="loadingExport" variant="primary" small></b-spinner>
    </button>
  </div>
</template>

<script>
import XLSX from "xlsx/xlsx";

export default {
  name: "ExportTableButton",
  props: {
    filtersTable: Object,
    columnFields: Array,
    loadingExport: Boolean,
  },
  methods: {
    exportData() {
      this.$emit("getAllAccountToDownloadExcel", this.filtersTable);
    },
    exportExcel(data) {
      const createXLSLFormatObj = [];
      const newXlsHeader = [];
      const vm = this;
      vm.columnFields
        .filter((column) => column.field !== "tools")
        .forEach((column) => newXlsHeader.push(column.label));
      createXLSLFormatObj.push(newXlsHeader);
      // eslint-disable-next-line array-callback-return
      data.map((value) => {
        if (value) {
          const innerRowData = [];
          vm.columnFields.map((val) => {
            let fieldValue = value[val.field_to_excel];
            if (val.field_to_excel === "modes") {
              fieldValue = this.modesFormat({
                exp: value.can_dispatch_exp,
                flatbet: value.can_dispatch_flatbed,
                ftl: value.can_dispatch_ftl,
                insurance: value.can_dispatch_insurance,
                ltl: value.can_dispatch_ltl,
                reefer: value.can_dispatch_reefer,
              });
            }
            if (val.field_to_excel === "is_branded") {
              fieldValue = value.is_branded ? "Yes" : "Not";
            }
            if (val.field_to_excel === "created_at") {
              fieldValue = this.dateFormat(fieldValue);
            }
            if (val.field_to_excel === "children_accounts") {
              fieldValue = this.accountsChildrenFormat(value.children_accounts);
            }
            if (val.field_to_excel === "network_id") {
              fieldValue = value.network_id ? "Yes" : "Not";
            }
            return innerRowData.push(fieldValue);
          });
          createXLSLFormatObj.push(innerRowData);
        }
      });
      // Crear el archivo Excel
      const fileName = "Account Management.xlsx";
      const wsName = "sheetname";
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, wsName);
      XLSX.writeFile(wb, fileName);
    },
    dateFormat(value) {
      return this.$options.filters.dateFormat(value);
    },
    modesFormat({
      exp, flatbet, ftl, insurance, ltl, reefer
    }) {
      const modes = [];
      if (ftl) modes.push("FTL");
      if (ltl) modes.push("LTL-Parcel");
      if (reefer) modes.push("Reefer");
      if (flatbet) modes.push("Flatbed");
      if (insurance) modes.push("Insurance");
      if (exp) modes.push("Exp");
      return modes.join(", ") || "";
    },
    accountsChildrenFormat(accounts) {
      if (!accounts) {
        return "";
      }
      const list = [];
      accounts.forEach((element) => {
        list.push(element.children_name);
      });
      return list.join(", ") || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  border-radius: 5px;
  border: 2px solid $color-border-container;
  color: $color-primary-company;
  background-color: #efefef;
  padding: 0.1rem 0.4rem;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    border-color: $color-border-container;
    background-color: rgba(0, 185, 204, 0.0588235294);
  }
}
</style>
