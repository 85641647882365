<template>
  <div class="account-edit-modal" v-loading="loading">
    <EditAccountDetails
      :accountId="account.id"
      @updateAccount="updateAccountHandler"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import EditAccountDetails from '../children/Accounts/components/EditAccount/EditAccountDetails.vue';

export default {
  name: "AccountEditModal",
  components: {
    EditAccountDetails
  },
  props: {
    account: Object
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    updateAccountHandler(data) {
      this.loading = data;
    },
    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-collapse-item__header {
    background-color: transparent;
  }
  .el-collapse-item__wrap {
    background-color: transparent;
  }
}
</style>
