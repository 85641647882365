<template>
  <div class="search-container">
    <i class="ion ion-search-outline" @click="search"></i>
    <input
      v-model="searchText"
      type="text"
      class="search-container__searcher"
      placeholder="Search account..."
      v-on:keyup.enter="search()"
    />
    <button
      v-show="searchText"
      class="search-container__delete-word"
      @click="clearSearchField"
    >
      X
    </button>
  </div>
</template>

<script>
export default {
  name: "SearchAccount",
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    search() {
      this.$emit("searchAccount", this.searchText);
    },
    clearSearchField() {
      this.searchText = null;
      this.$emit('accountClearSearchField');
    }
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  position: relative;
  display: flex;
  width: 30%;
  width: 240px;
  height: 35px;
  padding: 6px;
  border-radius: 12px;
  border: 1px solid $color-border-container;
  color: $color-border-container;
  background-color: $color-white;
  &__searcher {
    width: 100%;
    margin-left: 5px;
    padding-right: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    letter-spacing: 0px;
    color: $color-primary-company;
  }
  &__delete-word {
    background: none;
    border: none;
    color: #5a607f;
  }
}
</style>
