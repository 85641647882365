<template>
  <div class="filter-status">
    <div>
      <div class="container-list">
        <!-- <div class="" v-for="item in filterInformation" :key="item.text" :disabled="item.disabled">
          <span>{{ item.text }}</span>
          <el-switch size="small" v-model="item.value"> </el-switch>
        </div> -->
        <div class="" v-for="(item, index) in filterInformation" :key="item.text">
          <span>{{ item.text }}</span>
          <ToggleSelector
            :name="item.item"
            :index="index"
            :value="item.value"
            v-model="item.value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleSelector from "./ToggleSelector.vue";

export default {
  name: "FilterStatus",
  components: {
    ToggleSelector,
  },
  props: {
    savedFilters: Object,
  },
  data() {
    return {
      filterInformation: [
        {
          item: "status",
          text: "Status",
          field: "is_active",
          value: "n/a",
        },
        {
          item: "account",
          text: "Account Children",
          field: "children_accounts",
          value: "n/a",
        },
        {
          item: "branding",
          text: "Branding",
          field: "is_branded",
          value: "n/a",
        },
        {
          item: "magaya",
          text: "Magaya",
          field: "network_id",
          value: "n/a",
        },
      ],
    };
  },
  watch: {
    filter: {
      immediate: true,
      handler() {
        if (this.filter) {
          this.filterInformation = this.filter;
        }
      },
    },
  },
  created() {
    if (this.savedFilters?.filters) {
      const filters = { ...this.savedFilters.filters };
      const filterInfo = [...this.filterInformation];
      this.filterInformation = this.validateValueOfFilters(filterInfo, filters);
    }
  },
  methods: {
    validateValueOfFilters(filterInfo, filters) {
      filterInfo.forEach((filter) => {
        if (filter.field in filters) {
          if (filters[filter.field]) {
            filter.value = "on";
          } else if (filters[filter.field] === null || filters[filter.field] === undefined) {
            filter.value = "n/a";
          } else {
            filter.value = "off";
          }
        }
      });
      return filterInfo;
    },
    resetFilters() {
      // eslint-disable-next-line array-callback-return
      return this.filterInformation.map((el) => {
        el.value = "n/a";
      });
    },
    getStatusFilters() {
      return this.filterInformation;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-status {
  background-color: #efefef;
  border-radius: 5px;
  padding: 4px;
  width: 200px;
  &__titles {
    display: flex;
    align-items: center;
    margin: 0px;
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
    span {
      width: 80%;
      text-align: start;
      margin-left: 3px;
    }
  }
  &__icons {
    width: 10px;
    height: 10px;
  }
}

.container-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  span {
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
  }
}

::v-deep {
  .el-switch.is-checked .el-switch__core::after {
    margin-left: -12px;
  }
  .el-switch.is-checked .el-switch__core {
    border-color: $color-border-container !important;
    background-color: $color-border-container !important;
  }
  .el-switch__core {
    height: 13px;
    width: 30px !important;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 3px;
      border-radius: 100%;
      transition: all 0.3s;
      width: 11px;
      height: 11px;
      background-color: #fff;
      right: 23px;
    }
  }
}
</style>
