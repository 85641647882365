<template>
  <div class="columns-table" v-click-outside="hide">
    <div class="container-checkbox">
      <el-checkbox
        v-for="item in filterInformation"
        :key="item.text"
        v-model="item.value"
        :disabled="item.disabled"
      >
        {{ item.text }}
      </el-checkbox>
    </div>
    <div class="button-container">
      <button class="button-container__button" @click="applyColumns()">Apply</button>
      <button class="button-container__button" @click="resetColumns()">Reset</button>
    </div>
  </div>
</template>

<script>
import ClickOutside from "../../../directives/ClickOutside";

export default {
  name: "ColumnsTable",
  props: {
    savedFilters: Object,
  },
  data() {
    return {
      filterInformation: [],
      originalInformation: [
        {
          item: "company_name",
          text: "Company Name",
          value: false,
        },
        {
          item: "magaya",
          text: "Magaya",
          value: false,
        },
      ],
      triggerOpenedFilters: true,
    };
  },
  directives: {
    "click-outside": ClickOutside,
  },
  created() {
    this.filterInformation = [...this.originalInformation];
    if (this.savedFilters?.columns) {
      this.filterInformation = [...this.savedFilters.columns];
    }
  },
  methods: {
    hide() {
      if (this.triggerOpenedFilters) {
        this.triggerOpenedFilters = false;
        return;
      }
      this.$emit("closeColumns");
    },
    applyColumns() {
      this.$emit("selectedColumns", { filters: this.filterInformation, executed: true });
      this.hide();
    },
    resetColumns() {
      this.filterInformation = [...this.originalInformation];
      this.$emit("resetColumns");
      this.$emit("selectedColumns", { filters: this.filterInformation, executed: true });
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.columns-table {
  padding: 6px;
}

.container-checkbox {
  display: flex;
  flex-direction: column;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  &__button {
    border-radius: 15px;
    border: 1px solid $color-border-container;
    background-color: #fff;
    color: $color-border-container;
    margin: 2px;
    font-weight: bold;
    height: 25px;
    width: 70px;
    font-size: 11px;
    &:hover {
      background: $color-border-container;
      color: white;
    }
  }
}
::v-deep .el-checkbox {
  margin-right: 0px;
  display: flex;
  align-items: center;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: $color-border-container;
  border-color: $color-border-container;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 11px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
.checkbox-group-1 {
  display: flex;
  flex-wrap: wraps;
}
</style>
