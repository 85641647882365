<template>
  <div class="switch-toggle">
    <div class="switch-toggle-background">
      <div :class="optionClass('on')" class="switch-toggle-option">
        <input
          :id="'on_' + uniqueId"
          :name="uniqueName"
          type="radio"
          v-model="selectedOption"
          value="on"
        />
        <label :for="'on_' + uniqueId" title="ON">
          <i class="ion ion-checkmark-outline ion-true"></i>
        </label>
      </div>
      <div :class="optionClass('n/a')" class="switch-toggle-option">
        <input
          :id="'n/a_' + uniqueId"
          :name="uniqueName"
          type="radio"
          v-model="selectedOption"
          value="n/a"
        />
        <label :for="'n/a_' + uniqueId" title="N/A">
          <i class="ion ion-remove-outline ion-true"></i>
        </label>
      </div>
      <div :class="optionClass('off')" class="switch-toggle-option">
        <input
          :id="'off_' + uniqueId"
          :name="uniqueName"
          type="radio"
          v-model="selectedOption"
          value="off"
        />
        <label :for="'off_' + uniqueId" title="OFF">
          <i class="ion ion-close-outline ion-false"></i>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleSelector",
  props: {
    name: String,
    index: Number,
    value: {
      type: String,
      default: "n/a",
    }
  },
  data() {
    return {
      selectedOption: "n/a",
      uniqueId: Math.random().toString(36).substr(2, 9),
    };
  },
  created() {
    this.selectedOption = this.value;
  },
  watch: {
    selectedOption: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.selectedOption);
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedOption = this.value;
      }
    }
  },
  computed: {
    uniqueName() {
      return `${this.name}_${this.index}`;
    },
  },
  methods: {
    optionClass(option) {
      return {
        "switch-toggle-option": true,
        selected: this.selectedOption === option,
      };
    },
  },
};
</script>

<style lang="scss">
.switch-toggle {
  width: fit-content;
  border-radius: 20px;
  padding: 2px;
  background-color: #dcdfe6;
  &-background {
    border-radius: 20px;
    background: #dcdfe6;
    display: flex;
  }
}
.switch-toggle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.switch-toggle input + label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0px;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
}
.switch-toggle input:checked + label {
  background: #46b9cc;
  color: white;
  border-radius: 50%;
}
.switch-toggle input:checked + i {
  color: #5a5a5a;
}
</style>
