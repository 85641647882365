<template>
  <div class="filters-container" v-click-outside="hide">
    <div class="filters-container__content">
      <div class="filters-list">
        <div class="container-inputs__inputs">
          <span :class="validateFieldInfo(filtersData.account_name) ? 'active' : ''">
            <i class="ion ion-person-circle-outline mr-1"></i>
            Salesforce Account
          </span>
          <div class="input-container">
            <input v-model="filtersData.account_name" type="text" class="input-information" />
          </div>
        </div>
        <div class="container-inputs__inputs">
          <span :class="validateFieldInfo(filtersData.company_name) ? 'active' : ''">
            <i class="ion ion-person-circle-outline mr-1"></i>
            Company Name
          </span>
          <div class="input-container">
            <input v-model="filtersData.company_name" type="text" class="input-information" />
          </div>
        </div>
        <div class="container-inputs__inputs selector-list">
          <span :class="modesSelected && modesSelected.length >= 1 ? 'active' : ''">
            <i class="ion ion-pencil-outline mr-1"></i>
            Modes
          </span>
          <el-select
            v-model="modesSelected"
            multiple
            collapse-tags
            collapse-tags-tooltip
            placeholder="Select the modes to filter"
          >
            <el-option
              v-for="item in accountModesList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="container-inputs__inputs dates-container">
          <span :class="filtersData.init_created_at && filtersData.end_created_at ? 'active' : ''">
            <i class="el-icon-date mr-1"></i>
            Created Date
          </span>
          <div class="d-flex">
            <el-date-picker
              v-model="filtersData.init_created_at"
              type="date"
              placeholder="2023-00-00"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :class="emptyField['init_created_at'] ? 'missing-field' : ''"
            ></el-date-picker>
            <span class="">-</span>
            <el-date-picker
              v-model="filtersData.end_created_at"
              type="date"
              placeholder="2023-02-22"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :class="emptyField['end_created_at'] ? 'missing-field' : ''"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="filters-list">
        <StatusFilter
          @getStatusFilters="getStatusFilters"
          :savedFilters="savedFilters"
          ref="status-filters"
        />
      </div>
    </div>
    <div class="button-container">
      <button class="button-container__button" @click="applyFilters()">Apply</button>
      <button class="button-container__button" @click="resetFilters()">Reset</button>
      <button class="button-container__button" @click="$emit('closeFilters')">Close</button>
    </div>
  </div>
</template>

<script>
import ClickOutside from "../../../directives/ClickOutside";
import StatusFilter from "./StatusFilter.vue";

export default {
  name: "FilterAccount",
  props: {
    savedFilters: Object,
    accountModesList: Array,
  },
  components: {
    StatusFilter,
  },
  data() {
    return {
      triggerOpenedFilters: true,
      checkedFilters: [],
      modesSelected: [],
      filtersData: {
        company_name: "",
        account_name: "",
        init_created_at: "",
        end_created_at: "",
        is_branded: null,
        children_accounts: null,
        network_id: null,
        is_active: null,
      },
      emptyField: {
        init_created_at: false,
        end_created_at: false,
      },
    };
  },
  directives: {
    "click-outside": ClickOutside,
  },
  computed: {
    validateFieldInfo() {
      return (data) => {
        if (typeof data === "string") {
          return data.trim();
        }
        return data;
      };
    },
  },
  created() {
    if (this.savedFilters?.filters) {
      this.filtersData = this.savedFilters.filters;
      this.modesSelected = this.savedFilters.filters.modes;
    }
  },
  methods: {
    getStatusFilters() {
      const data = this.$refs["status-filters"].getStatusFilters();
      this.checkedFilters = this.validateStatusParam(data);
      const filterMappings = {
        Branding: "is_branded",
        Status: "is_active",
        "Account Children": "children_accounts",
        Magaya: "network_id",
      };
      this.checkedFilters.forEach((filter) => {
        const propertyName = filterMappings[filter.text];
        if (propertyName !== undefined) {
          this.filtersData[propertyName] = filter.value;
        }
      });
      this.$emit("getFiltersInformation", this.filtersData);
    },
    validateStatusParam(data) {
      return data.map((item) => {
        let { value } = item;
        if (value === "off") {
          value = false;
        } else if (value === "on") {
          value = true;
        } else if (value === "n/a") {
          value = null;
        }
        return { ...item, value };
      });
    },
    validateDateRange() {
      let validation = true;
      if (this.filtersData.init_created_at && !this.filtersData.end_created_at) {
        this.emptyField.end_created_at = true;
        validation = false;
      } else {
        this.emptyField.end_created_at = false;
      }
      if (this.filtersData.end_created_at && !this.filtersData.init_created_at) {
        this.emptyField.init_created_at = true;
        validation = false;
      } else {
        this.emptyField.init_created_at = false;
      }
      return validation;
    },
    hide() {
      if (this.triggerOpenedFilters) {
        this.triggerOpenedFilters = false;
        return;
      }
      this.$emit("closeFilters");
    },
    applyFilters() {
      const validateDates = this.validateDateRange();
      if (validateDates) {
        this.getStatusFilters();
        this.filtersData.modes = this.modesSelected;
        this.$emit("selectedFilters", { filters: this.filtersData, executed: true });
        this.hide();
      }
    },
    resetFilters() {
      this.$emit("resetFilters");
      this.$refs["status-filters"].resetFilters();
      this.modesSelected = [];
      this.filtersData = {
        company_name: "",
        account_name: "",
        init_created_at: "",
        end_created_at: "",
        modes: [],
        is_branded: null,
        children_accounts: null,
        network_id: null,
        is_active: null,
      };
      this.$emit("getFiltersInformation", this.filtersData);
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-container {
  &__content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 7px;
  }
}
.filters-list {
  background: #efefef;
  border-radius: 5px;
  padding: 6px;
  &:nth-child(1) {
    min-width: 230px;
  }
}
.active {
  color: $color-border-container !important;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  &__button {
    border-radius: 15px;
    border: 1px solid $color-border-container;
    background-color: #fff;
    color: $color-border-container;
    margin: 2px;
    font-weight: bold;
    height: 25px;
    width: 70px;
    font-size: 11px;
    &:hover {
      background: $color-border-container;
      color: white;
    }
  }
}
.dates-container {
  display: flex;
  flex-direction: column !important;
}
.container-inputs {
  &__inputs {
    flex-direction: row;
    margin-bottom: 8px;
    span {
      display: flex;
      align-items: center;
      color: #606266;
      font-size: 11px;
    }
    i {
      font-size: 13px;
    }
  }
  &__titles {
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
  }
  &__titles-active {
    font-size: 11px;
    font-family: $font-family-portal;
    color: $color-border-container;
  }
}
.input-container {
  @include input-information;
  border: 1px solid #fff;
  color: $color-primary-company;
  padding: 7px 0px 5px 5px;
  height: 25px;
  box-shadow: none;
  margin: 0px;
  font-family: $font-family-portal;
  border-radius: 5px;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}
.input-information {
  @include input-password;
  width: 100%;
  font-size: 11px;
  color: #606266;
}
.selector-list ::v-deep .el-input__inner {
  width: 100%;
  height: 30px;
}
::v-deep {
  .el-input__inner {
    border-radius: 5px;
    border: 1px solid #efefef;
    box-sizing: border-box;
    color: #606266;
    height: 25px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: "Montserrat";
    font-size: 11px;
    width: 140px;
  }
  .el-input__prefix,
  .el-input__suffix {
    top: -5px;
  }
  .el-date-editor.el-input {
    width: auto;
    &.missing-field input {
      border: 1px solid red !important;
    }
  }
  .el-input__icon {
    height: auto;
  }
  .el-select {
    width: none;
  }
  .el-input--suffix {
    width: 100%;
  }
  .el-select {
    width: 100% !important;
  }
  .el-input.is-focus .el-input__inner {
    border: none !important;
  }
}
</style>
