<template>
  <div class="account-detail-model">
    <div class="details-group">
      <label class="details-group__label">
        {{ $t("accountManagement.accountIds") }}
      </label>
      <p class="details-group__text">{{ account.account_name }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.companyName") }}</label>
      <p class="details-group__text">{{ account.company_name }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.phoneNumber") }}</label>
      <p class="details-group__text">{{ account.phone }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.companyAddress") }}</label>
      <p class="details-group__text">{{ account.company_address }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label">
        {{ $t("accountManagement.website") }}
      </label>
      <p class="details-group__text">{{ account.website }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label"> Country </label>
      <p class="details-group__text">{{ account.country_code }}</p>
    </div>
    <ul class="details-group" v-if="accountUsers.length > 0">
      <label class="details-group__label" for="">Users</label>
      <hr />
      <li class="details-group__item" v-for="item in accountUsers" :key="item.id">
        {{ item.first_name }} <br />
        {{ item.email }}, {{ item.role.name }}, {{ item.is_active ? "Active" : "Inactive" }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AccountDetailModel",
  props: {
    account: Object,
    accountUsers: Array,
  },
};
</script>

<style lang="scss" scoped>
.details-group {
  color: $color-primary-company;
  font-family: $font-family-portal;
  margin-top: 15px;
  padding: 0px;
  margin-bottom: 0px;
  &__label {
    @include font-standart-text;
    font-weight: bold;
    margin-bottom: 4px;
  }
  &__text {
    font-size: 14px;
    margin: 0px;
    font-weight: 400;
  }
  &__item {
    width: 90%;
    margin: 8px auto;
    font-size: 14px;
    font-weight: 400;
  }
}
hr {
  margin: 0px;
  border-top: 1px solid $color-border-container;
}
</style>
